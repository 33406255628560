// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name:'A benção',
    music:'ABencao',
    imgUrl:'https://static-kbo-site.knbcdn.com.br/discografia/albuns/c/6/c/9/5cf9f8630b22e.jpg'
  },
  {
    name:'Atos 2',
    music:'Atos2',
    imgUrl:'https://static-kbo-site.knbcdn.com.br/discografia/albuns/c/6/c/9/5cf9f8630b22e.jpg'
  },
  {
    name:'Ele vem',
    music:'EleVem',
    imgUrl:'https://static-kbo-site.knbcdn.com.br/discografia/albuns/c/6/c/9/5cf9f8630b22e.jpg'
  },
  {
    name:'Eu Creio',
    music:'EuCreio',
    imgUrl:'https://static-kbo-site.knbcdn.com.br/discografia/albuns/c/6/c/9/5cf9f8630b22e.jpg'
  },
  {
    name:'Noiva',
    music:'Noiva',
    imgUrl:'https://static-kbo-site.knbcdn.com.br/discografia/albuns/c/6/c/9/5cf9f8630b22e.jpg'
  },
  {
    name:'Minhas Guerras',
    music:'MinhasGuerras',
    imgUrl:'https://static-kbo-site.knbcdn.com.br/discografia/albuns/c/6/c/9/5cf9f8630b22e.jpg'
  },
  
]
