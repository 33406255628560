import './style.css'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function CardMusic({name, artista, music, imgUrl, nameArtista, breve}){

  function adicionar(){
    if(isBrowser){
      localStorage.setItem('modeView', 'Pc')
      localStorage.setItem('Artista',  `${artista}`);
      localStorage.setItem('Music',  `${music}`);
      localStorage.setItem('Multitrack',  `${name}`);
      window.location.href="/Multitrack.html";
    }else{
      localStorage.setItem('modeView', 'Moveis')
      localStorage.setItem('Artista',  `${artista}`);
      localStorage.setItem('Music',  `${music}`);
      localStorage.setItem('Multitrack',  `${name}`);
      window.location.href="/Multitrack.html";
    }
 
  }

  return(
    <div className="containerCapaMusic" onClick={adicionar}>
        
        <img src={imgUrl} style={{width:'100%', height:150}}/>

      <div className="contentCapa">
        {breve &&  <h3 className='breve'>Em breve</h3>}
        <p className='tituloArtista' style={{fontSize:14}}>{name}</p>
        {/* <p className='tituloMusic' style={{fontSize:12}}>{nameArtista}</p> */}
      </div>
    </div>  
  )
}