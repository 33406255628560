import './style.css'
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import CardMusic from '../CardMusic';
import { useState } from 'react';

export default function ContentArtistas({name, artista, arrayList}){
 const[scroll, setScroll] = useState(0)

 function handlleScroll(){
  let x = scroll + 150
  if(x > 0){
    x=0
  }
  setScroll(x)
 }

 function handlleScrollRight(){
  let x = scroll - Math.round(window.innerWidth / 2)
  let listW = Object.keys(arrayList).length * 300
  if((window.innerWidth - listW) > x){
    x = (window.innerWidth - listW) - 80
  }
  setScroll(x)
 }


  return(
    <>
  
      <div style={{width:50, height:50, backgroundColor: `rgba(4,120,87,0.5)`, position:'absolute', left:0,marginTop:110, zIndex:99,
      display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}
      onClick={handlleScroll}
      >
        <BiChevronLeft style={{width:50, height:30}} color="white"/>
      </div>

      <div style={{width:50, height:50, backgroundColor: `rgba(4,120,87,0.5)`, position:'absolute', right:0,marginTop:110, zIndex:99,
      display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}
      onClick={handlleScrollRight}
      >
        <BiChevronRight style={{width:50, height:30}} color="white"/>
      </div>
   

    <p className='artista'>{name}</p>

      <div className='contentCardMusic' id='cardMusiContent' style={{marginLeft:scroll}}> 
        {arrayList.map((item)=>{
          return(
            <>
              <CardMusic name={item.name} imgUrl={item.imgUrl} nameArtista={name} artista={artista} music={item.music} breve={item.breve}/>
            </>
          )
        })}
      </div>
    </>
  )
}