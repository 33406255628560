import './style.css'
import { useEffect, useState } from 'react';
import Modal from "react-modal";

import ContentArtistas from './components/ContentArtistas';

import fundo from './assets/fundo.jpg'

import alessandroVilasBoas from './arrayMusics/alessandroVilasBoas';
import AlineBarros from './arrayMusics/AlineBarros';
import andreAquino from './arrayMusics/andreAquino';
import gabrielGuedes from './arrayMusics/gabrielGuedes';
import morada from './arrayMusics/morada';
import hilsongsUnited from './arrayMusics/hilsongsUnited';
import lukasAgostinho from './arrayMusics/lukasAgostinho';
import gabiSampaio from './arrayMusics/gabiSampaio';

Modal.setAppElement("#root");

function App() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModalMoveis() {
    localStorage.setItem('modeView', 'Moveis')
    setIsOpen(false);
  }
  function closeModalPc() {
    localStorage.setItem('modeView', 'Pc')
    setIsOpen(false);
  }

  // useEffect(()=>{
  //   openModal()
  // },[])

  return (
    <>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModalMoveis}
        contentLabel="Example Modal"
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <h2>🔥Defina o Modo de Exibição</h2>
        <hr />
        <h4>
          Recomendações:
        </h4>
        <p>
         📱Dispositivos Móveis, é Recomendado usar o Modo VS 
        </p>
        <p>
         💻Pc é Recomendado usar o Modo Multitracks
        </p>
        <button onClick={closeModalMoveis}>VS</button>
        <button onClick={closeModalPc}>Multitracks</button>
      </Modal> */}

    <div className="App">
    <img src={fundo} className='banner'/>

    {/* <div className='headerApp'>
        <h4>Kontroll Multitracks</h4>
        <p>Classificação: Gospel</p>
    </div> */}


        <div className='containerArtistas' >
          <ContentArtistas arrayList={alessandroVilasBoas} name='Alessandro Vilas Boas' artista='AlessandroVilasBoas'/> 
          <ContentArtistas arrayList={AlineBarros} name='Aline Barros' artista='AlineBarros'/> 
          <ContentArtistas arrayList={andreAquino} name='André Aquino'  artista='AndreAquino' /> 
          <ContentArtistas arrayList={gabrielGuedes} name='Gabriel Guedes' artista='GabrielGuedes' /> 
          <ContentArtistas arrayList={gabiSampaio} name='Gabi Sampaio' artista='GabiSampaio' /> 
        </div>
    </div>
    </>

  );
}

export default App;
