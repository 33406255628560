// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name:'Acredito Sim',
    music:'AcreditoSim',
    imgUrl:'https://popnow.com.br/wp-content/uploads/2022/05/9Y2A5890-e1652109746328.jpg',
  },
  {
    name:'Adoração sem Limites',
    music:'AdoracaoSemLimites',
    imgUrl:'https://popnow.com.br/wp-content/uploads/2022/05/9Y2A5890-e1652109746328.jpg',
  },
  {
    name:'Casa do Pai',
    music:'CasaDoPai',
    imgUrl:'https://popnow.com.br/wp-content/uploads/2022/05/9Y2A5890-e1652109746328.jpg',
  },
  {
    name:'Consagração',
    music:'Consagracao',
    imgUrl:'https://popnow.com.br/wp-content/uploads/2022/05/9Y2A5890-e1652109746328.jpg',
  },
  {
    name:'Corre',
    music:'Corre',
    imgUrl:'https://popnow.com.br/wp-content/uploads/2022/05/9Y2A5890-e1652109746328.jpg',
  },
  {
    name:'Depois da Cruz',
    music:'DepoisDaCruz',
    imgUrl:'https://popnow.com.br/wp-content/uploads/2022/05/9Y2A5890-e1652109746328.jpg',
  },
  {
    name:'Digno é o Senhor',
    music:'DignoEOSenhor',
    imgUrl:'https://popnow.com.br/wp-content/uploads/2022/05/9Y2A5890-e1652109746328.jpg',
  },
]
