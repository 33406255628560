// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name:'Bom perfume',
    music:'BomPerfume',
    imgUrl:'https://s2.glbimg.com/kCUPAx20p2OfTu4Pt0t09a27IaE=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_b58693ed41d04a39826739159bf600a0/internal_photos/bs/2019/7/E/MArPzmQ66Hny2sYZKQLw/whatsapp-image-2019-09-21-at-16.54.57.jpeg',
  },
  {
    name:'Lindo és',
    music:'LindoEs',
    imgUrl:'https://s2.glbimg.com/kCUPAx20p2OfTu4Pt0t09a27IaE=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_b58693ed41d04a39826739159bf600a0/internal_photos/bs/2019/7/E/MArPzmQ66Hny2sYZKQLw/whatsapp-image-2019-09-21-at-16.54.57.jpeg',
  },
  {
    name:'Na terra como no céu',
    music:'NaTerraComoNoCeu',
    imgUrl:'https://s2.glbimg.com/kCUPAx20p2OfTu4Pt0t09a27IaE=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_b58693ed41d04a39826739159bf600a0/internal_photos/bs/2019/7/E/MArPzmQ66Hny2sYZKQLw/whatsapp-image-2019-09-21-at-16.54.57.jpeg',
  },
  {
    name:'Ousado amor',
    music:'OusadoAmor',
    imgUrl:'https://s2.glbimg.com/kCUPAx20p2OfTu4Pt0t09a27IaE=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_b58693ed41d04a39826739159bf600a0/internal_photos/bs/2019/7/E/MArPzmQ66Hny2sYZKQLw/whatsapp-image-2019-09-21-at-16.54.57.jpeg',
  },
]
