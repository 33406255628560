// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name:'O fogo nunca dorme',
    music:'OFogoNuncaDorme',
    imgUrl:'https://i.scdn.co/image/ab67616d0000b273befd9075065f46a791d00db0'
  },
  {
    name:'O Carpinteiro',
    music:'OCarpinteiro',
    imgUrl:'https://i.scdn.co/image/ab67616d0000b273befd9075065f46a791d00db0'
  },
  {
    name:'Deixa queimar',
    music:'DeixaQueimar',
    imgUrl:'https://i.scdn.co/image/ab67616d0000b273befd9075065f46a791d00db0'
  },
  {
    name:'Quero conhecer jesus',
    music:'QueroConhecerJesus',
    imgUrl:'https://i.scdn.co/image/ab67616d0000b273befd9075065f46a791d00db0'
  },
  {
    name:'Ser Mudado',
    music:'SerMudado',
    imgUrl:'https://i.scdn.co/image/ab67616d0000b273befd9075065f46a791d00db0'
  },
  
]
