// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name:'Em seu nome',
    music:'EmSeuNome',
    imgUrl:'https://cdns-images.dzcdn.net/images/artist/64d8d6a14af96b7363714e8d8f5bb301/264x264.jpg',
   },
  {
    name:'Pai de amor',
    music:'PaiDeAmor',
    imgUrl:'https://cdns-images.dzcdn.net/images/artist/64d8d6a14af96b7363714e8d8f5bb301/264x264.jpg',
   },
  {
    name:'Quando ele vem',
    music:'QuandoEleVem',
    imgUrl:'https://cdns-images.dzcdn.net/images/artist/64d8d6a14af96b7363714e8d8f5bb301/264x264.jpg',
   },
  {
    name:'Vai chover de novo',
    music:'VaiChoverDeNovo',
    imgUrl:'https://cdns-images.dzcdn.net/images/artist/64d8d6a14af96b7363714e8d8f5bb301/264x264.jpg',
   },
  {
    name:'Vem incendiar meu coração',
    music:'VemIncendiarMeuCoracao',
    imgUrl:'https://cdns-images.dzcdn.net/images/artist/64d8d6a14af96b7363714e8d8f5bb301/264x264.jpg',
   },
]
